export const API_URL = import.meta.env.VITE_API_URL
export const DOMAIN_URL_PRECIOS = import.meta.env.VITE_DOMAIN_URL_PRECIOS || ''
export const API_URL_USUARIOS: string | undefined = import.meta.env.VITE_API_URL_USUARIOS
export const PAIS_CACHE: keyof typeof CONFIG_BY_COUNTRY = import.meta.env.VITE_PAIS_CACHE

const CONFIG_BY_COUNTRY = {
	'63': { name: 'Spain', lang: 'es-ES', currency: 'EUR' },
	'136': { name: 'Mexico', lang: 'es-MX', currency: 'MXN' },
	'46': { name: 'Colombia', lang: 'es-CO', currency: 'COP' },
} as const

export const PAIS = CONFIG_BY_COUNTRY[PAIS_CACHE].name
export const PAIS_CURRENCY_CODE = CONFIG_BY_COUNTRY[PAIS_CACHE].currency
export const PAIS_LANG = CONFIG_BY_COUNTRY[PAIS_CACHE].lang
export const JETID: string = import.meta.env.VITE_JETID_PAYCOMET

export const GTM_ID: string = import.meta.env.VITE_GTM_ID
export const COOKIE_PRO_ID = import.meta.env.VITE_COOKIE_PRO_ID
export const EINSTEIN_ID = '500008751'

export const APP_MODE = import.meta.env.SSR ? false : navigator.userAgent.includes('rktwebview')